import { withPrefix, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import logo from "./../images/logo_LVR.svg"
import Helmet from "react-helmet"
import Nav from "./nav"


const Header = ({ siteTitle, menuLinks, strapiAlgemeen }) => (
  <>
    <Helmet>
        <script src={withPrefix('script.js')} type="text/javascript" />
    </Helmet>
    <header>
      <div className="sidebar" id="js-sidebar">
        <div className="nav-cross js-navToggle"></div>
        <Nav menuLinks={menuLinks} />
        <p className="mb-0 mt-3 mx-1 text-center sidebar-cta">
          {strapiAlgemeen.cta_header}<br />
          <a href={'tel:' + strapiAlgemeen.tel}>{strapiAlgemeen.tel}</a>
          &nbsp;of&nbsp;<br />
          <a href={'mailto:' + strapiAlgemeen.email}>{strapiAlgemeen.email}</a><br />
          {strapiAlgemeen.adres}
        </p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-3 col-lg-2">
            <a className="logo" href="/">
              <img src={logo} alt="Lien Van Rompaey - kinesitherapie" />
            </a>
          </div>          
          <div className="col-6 col-md-9 col-lg-10">
            <div className="align-items-end d-lg-flex flex-column justify-content-end d-none">
              <div className="badge badge-pill badge-primary">
                {strapiAlgemeen.cta_header}&nbsp;
                <a href={'tel:' + strapiAlgemeen.tel}>{strapiAlgemeen.tel}</a>
                &nbsp;of&nbsp;
                <a href={'mailto:' + strapiAlgemeen.email}>{strapiAlgemeen.email}</a>
              </div>
              <div className="header-adres">{strapiAlgemeen.adres}</div>
            </div>
            <div>
              
              <div className="d-flex d-lg-none justify-content-end mt-3 nav-toggle nav-toggle">
                <span className="nav-bars js-navToggle"></span>
              </div>
              <div className="d-none d-lg-block">
                <Nav menuLinks={menuLinks} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
