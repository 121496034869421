import { Link } from "gatsby"
import React from "react"

import lien from "./../images/lienvanrompaey.svg"


const Footer = ({ strapiAlgemeen }) => (
    <footer>
        <div className="footer-top">
            <div className="container">
                <div className="row">
                    <div className="col-2">
                        <img src={lien} height="63" alt="Lien Van Rompaey - kinesitherapie" />
                    </div>
                    <div className="col-10 text-right d-flex flex-column justify-content-center">
                        <div className="d-flex flex-column justify-content-right d-md-block">
                            {strapiAlgemeen.adres}
                            <a href={'tel:' + strapiAlgemeen.tel}>{strapiAlgemeen.tel}</a>
                            <a href={'mailto:' + strapiAlgemeen.email}>{strapiAlgemeen.email}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center text-sm-right d-flex flex-column justify-content-center d-sm-block">
                        <Link to="/privacy">Privacy</Link>
                        <span>Website door Lien Stevens</span>
                        <span>Foto's door Joke Devoldre</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
