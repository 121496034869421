import { Link } from "gatsby"
import React from "react"

const isPartiallyActive = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { className: "nav-link active active-page" } : null;


const Nav = ({ menuLinks }) => (
    <nav>
    <ul className="d-flex flex-column flex-lg-row justify-content-lg-end nav text-center">
        {menuLinks.map(link => (
        <li key={link.name} className="nav-item">
            <Link 
                className="nav-link active" 
                getProps={link.name === "home" ? undefined : isPartiallyActive} 
                to={link.link}>
                    {link.name}
            </Link>
        </li>
        ))}
    </ul>
    </nav>
)

export default Nav
